import React from 'react'
import { Link } from 'gatsby'
import { translate } from "react-i18next"

import Layout from '../components/layouts/baseLayout'
import Form from '../components/contact_form/form'
import Navbar from '../components/navbar/navbar'

import allStyle from "../styles/all.module.scss"


import uliege from "../images/index/uliege_logo_gris.png"
import liege from "../images/index/liege.png"
import chu from "../images/index/chu.png"
import cpas from "../images/index/cpas.png"
import province from "../images/index/province.png"
import helmo from "../images/index/helmo.png"

import android_store from "../images/index/playstore.svg"
import appstore from "../images/index/appstore.svg"
import mobile_preview from "../images/index/mobile.png"

import * as consts from '../const'

function About (props) {
  const { t } = props;
  return (
    <Layout>

      <main className={allStyle.annexe}>
        <div className={allStyle.page}>
        <div className={allStyle.top_section}>
          <h3>{t("solution")}</h3>
          <h5>{t("modern_solution")}</h5>
          <p>{t("modern_solution_text")}</p>
          <h5>{t("environement")}</h5>
          <p>{t("environement_text")}</p>
          <h5>{t("integration")}</h5>
          <p>{t("integration_text")} </p>
        </div>
        <div className={allStyle.mobile_application}>
          <img className={allStyle.mobile} src={mobile_preview} alt="aperçu application"/>
          <div>
            <h3>{t("our_app")}</h3>
            <p>{t("our_app_text")}</p>
            <div className={allStyle.stores}>
              <a href={consts.PlayStore}><img src={android_store} alt="play store"/></a>
              <a href={consts.AppStore}><img src={appstore} alt="appstore"/></a>
            </div>
          </div>

        </div>
        <div className={allStyle.history}>
          <h3>{t("our_history")}</h3>

          <div className={allStyle.text}>

            <div className={allStyle.dates}>

              <h4>2015</h4>
              <p>{t("first_date")}</p>
            </div>
            <div className={allStyle.dates}>
              <h4>2016</h4>
              <p>{t("second_date")} </p>
            </div>
            <div className={allStyle.dates}>
              <h4>2017</h4>
              <p>{t("third_date")}</p>
            </div>
            <div className={allStyle.dates}>
              <h4>2018</h4>
              <p>{t("fourth_date")}</p>
            </div>
            <div className={allStyle.dates}>
              <h4>2019</h4>
              <p>{t("fifth_date")}</p>
            </div>

          </div>

        </div>

        <div className={allStyle.partenaires}>
          <h3>{t("our_partners")}</h3>
          <p>{t("our_partners_text")}</p>
          <div className={allStyle.logo}>
            <img src={uliege} alt="logo de la ville de liège"/>
            <img src={chu} alt="logo du CHU"/>
            <img src={liege} alt="logo de la ville de liège"/>
            <img src={cpas} alt="logo du cpas"/>
            <img src={province} alt="logo du la province de liège"/>
            <img src={helmo} alt="logo HELMO"/>
          </div>
        </div>
        <div className={allStyle.contact}>
          <h3>{t("contact_us")}</h3>
          <p>{t("contact_us_text")}</p>
          <Form/>
        </div>



        </div>
      </main>
    </Layout>)
}


export default translate("all")(About)